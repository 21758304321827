import React from 'react';
import './MainSiteButton.css';

const MainSiteButton = () => (
  <a href="https://www.auslawcalc.store/" className="main-site-button">
    Learn more
    <span className="arrow">→</span>
  </a>
);

export default MainSiteButton;
