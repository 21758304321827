// DocumentExport.jsx
import React, { useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { useSelector } from 'react-redux';
import api from '../services/api';
import ExportOverlay from './ExportOverlay';

const DocumentExport = ({ state, court, matter, dateOfOrder }) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const requirements = useSelector((reduxState) => reduxState.requirements);

  const handleOverlayToggle = () => {
    setShowOverlay((prev) => !prev);
  };

  const handleOverlayClose = () => {
    setShowOverlay(false);
  };

  const handleExport = async (additionalData) => {
    try {
      mixpanel.track('Document Export Initiated', { state, court, matter, dateOfOrder });
      const formattedRequirements = {
        topDates: requirements.topDates,
        requirements: requirements.requirements.map((req) => ({
          ...req,
          date: req.chosenDate || req.calculatedDate
        }))
      };

      const response = await api.post(
        '/document/generate',
        { state, court, matter, dateOfOrder, requirements: formattedRequirements, additionalData },
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          },
        }
      );

      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'requirements.docx');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      mixpanel.track('Document Export Completed', { state, court, matter, dateOfOrder });
    } catch (error) {
      console.error('Error exporting document:', error);
    }
  };

  return (
    <>
      <button type="button" className="document-export-btn" onClick={handleOverlayToggle}>
        <div className="export-icon-container">
          <img
            src={`${process.env.PUBLIC_URL}/Images/simpledocexport.png`}
            alt="Export"
            className="export-icon"
          />
        </div>
        <div className="export-text-container">
          <span className="export-text">Export</span>
          <span className="additional-fields-text">Additional Fields</span>
        </div>
        <div className="arrow-container">
          <img
            src={`${process.env.PUBLIC_URL}/Images/down-arrow.png`}
            alt="Arrow"
            className="arrow-icon"
          />
        </div>
      </button>
      {showOverlay && (
        <ExportOverlay
          onClose={handleOverlayClose}
          onExport={handleExport}
        />
      )}
    </>
  );
};

export default DocumentExport;
