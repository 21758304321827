import { createSlice } from '@reduxjs/toolkit';

const matterSlice = createSlice({
  name: 'matter',
  initialState: {
    stateId: null,
    courtId: null,
    matterId: null,
    dateOfOrder: '',
    savedCalculationId: null,
    // Add these new properties
    criminalDates: {},
    calculatedCriminalDates: {},
    isCriminalDivision: false,
  },
  reducers: {
    setStateId: (state, action) => {
      state.stateId = action.payload;
    },
    setCourtId: (state, action) => {
      state.courtId = action.payload;
    },
    setMatterId: (state, action) => {
      state.matterId = action.payload;
    },
    setDateOfOrder: (state, action) => {
      state.dateOfOrder = action.payload;
    },
    setSavedCalculationId: (state, action) => {
      state.savedCalculationId = action.payload;
    },
    // Add these new reducers
    setCriminalDate: (state, action) => {
      state.criminalDates = { ...state.criminalDates, ...action.payload };
    },
    setCriminalCalculatedDates: (state, action) => {
      state.calculatedCriminalDates = action.payload;
    },
    setIsCriminalDivision: (state, action) => {
      state.isCriminalDivision = action.payload;
    },
  },
});

export const {
  setStateId,
  setCourtId,
  setMatterId,
  setDateOfOrder,
  setSavedCalculationId,
  // Add these new actions
  setCriminalDate,
  setCriminalCalculatedDates,
  setIsCriminalDivision,
} = matterSlice.actions;

export default matterSlice.reducer;
