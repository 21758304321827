/* eslint-disable max-len */
import React, { useState } from 'react';
import './QuickTour.css';

const QuickTour = () => {
  const [showTour, setShowTour] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);

  // Array of slides, each with a screenshot and a description
  const slides = [
    {
      title: 'Welcome to Aus Law Calc!',
      description: 'Let’s take a quick tour of the main features.',
      image: '/Images/QuickTour - AdjustDate.png', // Path to your screenshot
      highlightClass: null, // No pulsing highlight for the first slide
    },
    {
      title: 'Select Your State',
      description: 'Start by selecting your state in this dropdown.',
      image: '/Images/QuickTour - SelectState.png', // Path to your screenshot
      highlightClass: 'highlight-state', // Pulsing icon pointing to state dropdown
      highlightSize: '20px',
      highlightTop: '35%',
      highlightLeft: '38%',
    },
    {
      title: 'Select Court',
      description: 'Next, select the relevant court for your case.',
      image: '/Images/QuickTour - SelectCourt.png', // Path to your screenshot
      highlightClass: 'highlight-court', // Pulsing icon pointing to court dropdown
      highlightSize: '20px',
      highlightTop: '41%',
      highlightLeft: '38%',
    },
    {
      title: 'Criminal or Civil Case',
      description: 'Select whether it\'s a Criminal or Civil matter.',
      image: '/Images/QuickTour - Criminal or Civil.png',
      highlightClass: 'highlight-matter',
      highlightSize: '20px',
      highlightTop: '45.5%',
      highlightLeft: '37.1%',
    },
    {
      title: 'Select Matter Type',
      description: 'Choose which matter this belongs to.',
      image: '/Images/QuickTour - SelectMatter.png',
      highlightClass: 'highlight-matter',
      highlightSize: '20px',
      highlightTop: '51%',
      highlightLeft: '43%',
    },
    {
      title: 'Enter Date of Order',
      description: 'Input the date of the order to calculate relevant dates.',
      image: '/Images/QuickTour - SelectDate.png',
      highlightClass: 'highlight-date',
      highlightSize: '20px',
      highlightTop: '57%',
      highlightLeft: '40%',
    },
    {
      title: 'Adjust dates as needed',
      description: 'Use selectors to change the dates.',
      image: '/Images/QuickTour - AdjustDate.png',
      highlightClass: 'highlight-date',
      highlightSize: '20px',
      highlightTop: '33.4%',
      highlightLeft: '62.4%',
    },
    {
      title: 'Export your document',
      description: 'Fill in the optional fields to pre-fill your order template & hit export',
      image: '/Images/QuickTour - ExportDocument.png',
      highlightClass: 'highlight-date',
      highlightSize: '20px',
      highlightTop: '26.4%',
      highlightLeft: '66.5%',
    },
    {
      title: 'Open completed consent order',
      description: 'Go to \'Downloads\' folder to review & share your completed consent order',
      image: '/Images/QuickTour - CompletedOrder.png',
    },
    // Add more slides as necessary
  ];

  const nextSlide = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const prevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const restartTour = () => {
    setCurrentSlide(0);
    setShowTour(true);
  };

  return (
    <div>
      {showTour && (
        <div className="tour-overlay">
          <div className="tour-popup">
            <button type="button" className="close-btn" onClick={() => setShowTour(false)}>X</button>
            <h3 className="tour-title">{slides[currentSlide].title}</h3>
            <p className="tour-description">{slides[currentSlide].description}</p>

            {/* Display the image for the current slide */}
            <div className="tour-screenshot-container">
              <img src={slides[currentSlide].image} alt={`Slide ${currentSlide + 1}`} className="tour-screenshot" />
            </div>

            {/* Next/Previous Buttons */}
            <div className="tour-controls">
              {currentSlide > 0 && <button type="button" onClick={prevSlide}>Back</button>}
              {currentSlide < slides.length - 1 && <button type="button" onClick={nextSlide}>Next</button>}
            </div>
          </div>

          {/* Highlight element with pulsing circle */}
          {slides[currentSlide].highlightClass && (
            <div
              className={`pulsing-circle ${slides[currentSlide].highlightClass}`}
              style={{
                width: slides[currentSlide].highlightSize || '50px',
                height: slides[currentSlide].highlightSize || '50px',
                top: slides[currentSlide].highlightTop,
                left: slides[currentSlide].highlightLeft,
              }}
            />
          )}
        </div>
      )}
      {/* Button to restart the tour */}
      {!showTour && <button type="button" className="tour-restart-btn" onClick={restartTour}>Quick Tour</button>}
    </div>
  );
};

export default QuickTour;
