/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useTransition } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { calculateCriminalDates, saveCalculation, loadCalculation } from './services/apiService';
import { setRequirements, fetchRequirements } from './store/requirementsSlice';
import { setSavedCalculationId, setStateId, setCourtId, setMatterId, setDateOfOrder } from './store/matterSlice';
import ProceedingTypeCheckbox from './components/ProceedingTypeCheckbox';
import CriminalDateInput from './components/CriminalDateInput';
import CriminalRequirementList from './components/CriminalRequirementList';
import { useMatterCalculations } from './hooks/useMatterCalculations';
import StateSelection from './components/StateSelection';
import CourtSelection from './components/CourtSelection';
import MatterSelection from './components/MatterSelection';
import DateInput from './components/DateInput';
import RequirementList from './components/RequirementList';
import DocumentExport from './components/DocumentExport';
import DownloadableLink from './components/DownloadableLink';
import QuickTour from './components/QuickTour';
import MainSiteButton from './components/MainSiteButton'; // Add this import

function AppContent() {
  const [isCriminalDivision, setIsCriminalDivision] = useState(false);
  const [isCivilMatter, setIsCivilMatter] = useState(false);
  const [selectedProceedingTypes, setSelectedProceedingTypes] = useState({
    Committal: false,
    Trial: false,
    Plea: false,
    Appeal: false,
    Contravention: false
  });
  const [criminalDates, setCriminalDates] = useState({
    committalDate: null,
    trialDate: null,
    pleaHearingDate: null,
    appealHearingDate: null,
    contraventionHearingDate: null
  });
  const [calculationId, setCalculationId] = useState('');

  const dispatch = useDispatch();
  const {
    stateId,
    courtId,
    matterId,
    dateOfOrder,
    savedCalculationId
  } = useSelector((state) => state.matter || {});
  const { requirements = [] } = useSelector((state) => state.requirements || {});
  const { isLoading, error: queryError } = useMatterCalculations();

  const navigate = useNavigate();

  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');
    const isDevelopment = process.env.NODE_ENV === 'development';
    const bypassAuth = isDevelopment && process.env.REACT_APP_BYPASS_AUTH === 'true';

    console.log('Environment:', process.env.NODE_ENV);
    console.log('REACT_APP_BYPASS_AUTH:', process.env.REACT_APP_BYPASS_AUTH);
    console.log('isDevelopment:', isDevelopment);
    console.log('bypassAuth:', bypassAuth);
    console.log('userEmail:', userEmail);

    if (!userEmail && !bypassAuth) {
      console.log('Redirecting to login');
      navigate('/login');
    } else {
      console.log('User authenticated or auth bypassed');
    }
  }, [navigate]);

  const handleSaveCalculation = async () => {
    try {
      if (!requirements || !Array.isArray(requirements)) {
        console.error('Requirements are not in the expected format:', requirements);
        alert('Error: Requirements are not in the expected format');
        return;
      }

      const calculatedDates = requirements.reduce((acc, req) => ({
        ...acc,
        [req.description]: req.chosenDate || req.calculatedDate
      }), {});

      const requirementsData = requirements.reduce((acc, req) => ({
        ...acc,
        [req.description]: {
          offsetValue: req.offsetValue,
          offsetUnit: req.offsetUnit,
          direction: req.direction,
          fromDateType: req.fromDateType,
          chosenDate: req.chosenDate || req.calculatedDate
        }
      }), {});

      const calculationData = {
        stateId,
        courtId,
        matterId,
        dateOfOrder,
        calculatedDates,
        requirements: requirementsData
      };

      console.log('Saving calculation data:', calculationData);

      const response = await saveCalculation(calculationData);
      dispatch(setSavedCalculationId(response.uniqueId));
      alert(`Calculation saved with ID: ${response.uniqueId}`);
    } catch (error) {
      console.error('Error saving calculation:', error);
      alert(`Failed to save calculation: ${error.message}`);
    }
  };

  const handleLoadCalculation = async (loadedCalculationId) => {
    try {
      const savedCalculation = await loadCalculation(loadedCalculationId);
      // Assuming you have actions for setting these values in your Redux store
      dispatch(setStateId(savedCalculation.stateId));
      dispatch(setCourtId(savedCalculation.courtId));
      dispatch(setMatterId(savedCalculation.matterId));
      dispatch(setDateOfOrder(savedCalculation.dateOfOrder));
      dispatch(setRequirements(savedCalculation.requirements));
    } catch (error) {
      console.error('Error loading calculation:', error);
      alert(`Failed to load calculation: ${error.message}`);
    }
  };

  const proceedingTypeToDateField = {
    Committal: 'committalDate',
    Trial: 'trialDate',
    Plea: 'pleaDate',
    Appeal: 'appealDate',
    Contravention: 'contraventionDate', // Updated mapping
  };

  const [criminalRequirements, setCriminalRequirements] = useState([]);
  const [calculatedCriminalDates, setCalculatedCriminalDates] = useState({});

  useEffect(() => {
    if (isCriminalDivision && Object.values(selectedProceedingTypes).some(Boolean) && Object.values(criminalDates).some((date) => date !== null)) {
      const fetchCriminalDates = async () => {
        try {
          const selectedTypes = Object.keys(selectedProceedingTypes).filter((type) => selectedProceedingTypes[type]);
          const response = await calculateCriminalDates(criminalDates, selectedTypes);
          console.log('Calculated criminal dates:', response.calculatedDates);
          console.log('Criminal requirements:', response.requirements);
          setCalculatedCriminalDates(response.calculatedDates);
          setCriminalRequirements(response.requirements);
        } catch (error) {
          console.error('Error calculating criminal dates:', error);
          setCalculatedCriminalDates({});
          setCriminalRequirements([]);
        }
      };

      fetchCriminalDates();
    }
  }, [isCriminalDivision, criminalDates, selectedProceedingTypes]);

  const handleCriminalDateChange = (dateType, newDate) => {
    setCriminalDates((prevDates) => ({
      ...prevDates,
      [dateType]: newDate instanceof Date ? newDate.toISOString() : newDate
    }));
  };

  const [isPending, startTransition] = useTransition();

  const handleCriminalDivisionSelect = () => {
    setIsCriminalDivision(true);
    setIsCivilMatter(false);
  };

  const handleCivilMatterSelect = () => {
    setIsCivilMatter(true);
    setIsCriminalDivision(false);
    // Clear criminal-related state
    setSelectedProceedingTypes({
      Committal: false,
      Trial: false,
      Plea: false,
      Appeal: false,
      Contravention: false
    });
    setCriminalDates({
      committalDate: null,
      trialDate: null,
      pleaHearingDate: null,
      appealHearingDate: null,
      contraventionHearingDate: null
    });
    setCalculatedCriminalDates({});
    // Clear civil-related state to ensure fresh calculations
    dispatch(setMatterId(null));
    dispatch(setDateOfOrder(null));
    dispatch(setRequirements([]));
  };

  useEffect(() => {
    if (isCivilMatter && matterId && dateOfOrder) {
      console.log('Civil matter selected:', { matterId, dateOfOrder });
      dispatch(fetchRequirements(matterId, dateOfOrder));
    }
  }, [isCivilMatter, matterId, dateOfOrder, dispatch]);

  const handleProceedingTypeSelect = (proceedingType) => {
    setSelectedProceedingTypes((prev) => ({
      ...prev,
      [proceedingType]: !prev[proceedingType]
    }));
  };

  // Add this new state for QuickTour
  const [showQuickTour, setShowQuickTour] = useState(true);

  return (
    <div className="App">
      {/* QuickTour component */}
      <QuickTour showTour={showQuickTour} setShowTour={setShowQuickTour} />

      <div className="left-column">
        <div className="header">
          <a href="https://auslawcalc.store" target="_blank" rel="noopener noreferrer">
            <img src={`${process.env.PUBLIC_URL}/Images/logo.png`} alt="Aus Law Calc Logo" className="logo" />
          </a>
          <MainSiteButton />
        </div>
        {queryError && <div className="notification">{queryError.message}</div>}
        {/* Commenting out CalculationIdInput
        <CalculationIdInput
          calculationId={calculationId}
          setCalculationId={setCalculationId}
          onLoadCalculation={handleLoadCalculation}
        />
        */}
        <div className="input-section">
          {isPending && <div className="loading-indicator">Loading...</div>}
          <StateSelection onSelectState={(value) => startTransition(() => dispatch(setStateId(value)))} />
          <CourtSelection
            stateId={stateId}
            onSelectCourt={(value) => startTransition(() => dispatch(setCourtId(value)))}
            disabled={!stateId}
          />
          {courtId && (
            <div className="division-selection">
              <button type="button" onClick={handleCriminalDivisionSelect}>Criminal Matter</button>
              <button type="button" onClick={handleCivilMatterSelect}>Civil Matter</button>
            </div>
          )}
          {isCriminalDivision && (
          <>
            <div className="proceeding-types-container">
              {Object.keys(selectedProceedingTypes).map((proceedingType) => (
                <ProceedingTypeCheckbox
                  key={proceedingType}
                  type={proceedingType}
                  isChecked={selectedProceedingTypes[proceedingType]}
                  onCheck={() => handleProceedingTypeSelect(proceedingType)}
                />
              ))}
            </div>
            {Object.entries(selectedProceedingTypes).map(([proceedingType, isSelected]) => isSelected && (
            <CriminalDateInput
              key={proceedingType}
              label={`${proceedingType} Date`}
              date={criminalDates[proceedingTypeToDateField[proceedingType]]}
              onDateChange={(date) => handleCriminalDateChange(proceedingTypeToDateField[proceedingType], date)}
            />
            ))}
          </>
          )}
          {isCivilMatter && (
          <>
            <MatterSelection
              courtId={courtId}
              onSelectMatter={(value) => startTransition(() => dispatch(setMatterId(value)))}
              disabled={!courtId}
            />
            <DateInput onDateChange={(value) => startTransition(() => dispatch(setDateOfOrder(value)))} />
          </>
          )}
          <div className="date-and-save-container">
            {dateOfOrder && (
              <span className="formatted-date">
                {new Date(Date.parse(dateOfOrder)).toLocaleDateString('en-US', {
                  weekday: 'long',
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric'
                })}
              </span>
            )}
          </div>
          {stateId && courtId && (
            <DownloadableLink stateId={stateId} courtId={courtId} isCriminalDivision={isCriminalDivision} />
          )}
          {savedCalculationId && (
            <p className="saved-calculation-id">
              Saved Calculation ID:
              {' '}
              {savedCalculationId}
            </p>
          )}
        </div>
      </div>
      <div className="right-column">
        <div className="requirements-header">
          <h2 className="requirements-heading">Calculated Dates</h2>
          {dateOfOrder && requirements && requirements.length > 0 && (
            <DocumentExport
              state={stateId}
              court={courtId}
              matter={matterId}
              dateOfOrder={dateOfOrder}
              requirements={requirements}
            />
          )}
        </div>
        <div className="results-section">
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <>
              {isCriminalDivision && Object.values(selectedProceedingTypes).some(Boolean) && (
                <CriminalRequirementList
                  calculatedDates={calculatedCriminalDates}
                  onDateChange={(description, newDate) => {
                    const dateType = Object.keys(proceedingTypeToDateField).find((key) => description.toLowerCase().includes(key.toLowerCase()));
                    if (dateType) {
                      handleCriminalDateChange(proceedingTypeToDateField[dateType], newDate);
                    }
                  }}
                  selectedProceedingType={selectedProceedingTypes}
                  requirements={criminalRequirements}
                />
              )}
              {isCivilMatter && dateOfOrder && requirements && requirements.length > 0 && (
                <>
                  {console.log('Rendering civil requirements:', { dateOfOrder, requirements })}
                  <RequirementList
                    onSaveCalculation={handleSaveCalculation}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AppContent;
